<!-- eslint-disable indent -->
<template>
  <div>
    <b-card class="invoice-preview-card">
      <b-card-header>
        <div class="d-flex justify-content-between flex-wrap">
          <h2 class="my-auto">
            Configurateur de langues<br>
          </h2>
        </div>
        <div class="text-end">
          <b-button
          variant="success"
          @click="save"
        >
          Télécharger</b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-col cols="6">
          <div>
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Francais</b-th>
                  <b-th>Anglais</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in flattened" :key="index">
                  <b-td>
                    <p>{{ newFlattened[index] }}</p>
                  </b-td>
                  <b-td style="width: 50%;">
                    <textarea name="" id="" v-model="processed[index]"
                      style="border:none; outline:none; width:700px; resize: none; height: 100%;" class="w-100 h-100"></textarea>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BDropdown,
  BButton,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormTextarea,
} from 'bootstrap-vue'
/* import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' */

import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

// -------- store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import languagesStoreModule from '@/store/languages'

// importation du fichier Json
import dataJson from '@/json/fr.json'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BCardHeader,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BCardFooter,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      loadingUpdateConfig: false,
      flattened: '',
      processed: '',
      unflattened: '',
      newFlattened: '',
      newProcessed: '',

      tableColumns: [
        {
          key: 'type',
          label: 'Français',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Anglais',
        },
      ],
    }
  },
  computed: {},
  setup() {
    const requiredStoreModules = [
      { path: 'languages', module: languagesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  mounted() {
    this.getValue()
  },
  methods: {

    flattenObject(obj, parentKey = '') {
      const flattened = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const newKey = parentKey ? `${parentKey}.${key}` : key
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, this.flattenObject(obj[key], newKey))
          } else {
            flattened[newKey] = obj[key]
          }
        }
      }
      return flattened
    },

    processValues(flattenedObj) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in flattenedObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (flattenedObj.hasOwnProperty(key)) {
          // Process the values here, for example, convert to uppercase
          // eslint-disable-next-line no-param-reassign
          flattenedObj[key] = flattenedObj[key].toUpperCase()
        }
      }
      return flattenedObj
    },

    unflattenObject(flattenedObj) {
      const unflattened = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in flattenedObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (flattenedObj.hasOwnProperty(key)) {
          const keys = key.split('.')
          let tempObj = unflattened
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < keys.length; i++) {
            if (i === keys.length - 1) {
              tempObj[keys[i]] = flattenedObj[key]
            } else {
              tempObj[keys[i]] = tempObj[keys[i]] || {}
              tempObj = tempObj[keys[i]]
            }
          }
        }
      }
      return unflattened
    },

    async getValue() {
      // eslint-disable-next-line no-unused-expressions
      this.flattened = await this.flattenObject(dataJson)
      console.log('flat', this.flattened)
      // eslint-disable-next-line no-unused-expressions
      this.newFlattened = await JSON.parse(JSON.stringify(this.flattened))
      console.log('newFlattened', this.newFlattened)
      // eslint-disable-next-line no-unused-expressions, no-undef
      this.processed = await this.processValues(this.flattened)
      console.log('processed', this.processed)
      this.newProcessed = await this.processed
      console.log('newProcessed', this.newProcessed)
      console.log('unflacted', await this.unflattenObject(this.newProcessed))
    },

    save() {
      // eslint-disable-next-line no-underscore-dangle
      const unflattened = this.unflattenObject(this.newProcessed)
      const donneesJSONString = JSON.stringify(unflattened)
      const blob = new Blob([donneesJSONString], { type: 'application/json' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      // eslint-disable-next-line no-undef
      a.download = 'en.json'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
<style scoped></style>
