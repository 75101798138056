import { BaseService } from './base.service'

export class LanguagesService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // eslint-disable-next-line class-methods-use-this, consistent-return
  processValues(flattenedObj) {
    console.log('je suis la')
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in flattenedObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (flattenedObj.hasOwnProperty(key)) {
        // Process the values here, for example, convert to uppercase
        // eslint-disable-next-line no-param-reassign
        flattenedObj[key] = flattenedObj[key].toUpperCase()
      }
      return flattenedObj
    }
  }

  // eslint-disable-next-line class-methods-use-this
  flattenObject(obj, parentKey = '') {
    console.log('je suis au niveau des service')
    const flattened = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // eslint-disable-next-line no-undef
          Object.assign(flattened, this.flattenObject(obj[key], newKey))
        } else {
          flattened[newKey] = obj[key]
        }
      }
    }
    return flattened
  }

  // eslint-disable-next-line class-methods-use-this
  unflattenObject(flattenedObj) {
    const unflattened = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in flattenedObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (flattenedObj.hasOwnProperty(key)) {
        const keys = key.split('.')
        let tempObj = unflattened
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < keys.length; i++) {
          if (i === keys.length - 1) {
            tempObj[keys[i]] = flattenedObj[key]
          } else {
            tempObj[keys[i]] = tempObj[keys[i]] || {}
            tempObj = tempObj[keys[i]]
          }
        }
      }
    }
    return unflattened
  }

  // eslint-disable-next-line class-methods-use-this
  save(unflattened) {
    // eslint-disable-next-line no-undef
    const donneesJSONString = JSON.stringify(unflattened)
    const blob = new Blob([donneesJSONString], { type: 'application/json' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    // eslint-disable-next-line no-undef
    a.download = `${newLangue}.json`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
}

export default new LanguagesService()
