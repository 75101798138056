/* eslint-disable prefer-const */
import languagesService from '@/services/http/languages.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    async processValues({ rootState }, data) {
      const res = await languagesService.processValues(data)
      return res
    },

    // eslint-disable-next-line no-unused-vars
    async flattenObject({ rootState }, data) {
      console.log("J'ai atteint le store")
      const res = languagesService.flattenObject(data)
      return res
    },

    // eslint-disable-next-line no-unused-vars
    async unflattenObject({ rootState }, data) {
      const res = languagesService.unflattenObject(data)
      return res
    },

    // eslint-disable-next-line no-unused-vars
    async saveJson({ rootState }, data) {
      const res = languagesService.save(data)
      return res
    },
  },
}
